<template>
  <div class="flex items-center justify-center w-full px-16 sm:px-24">
    <div class="flex flex-col max-w-md w-full text-center">
      <img :src="logoImage" alt="avisfordele" class="w-230 mx-auto">
      <h2 class="mt-24 mb-8 font-extrabold text-2xl leading-bigger">Sign in to your account</h2>
      <p class="mb-32 text-grey-400">Contact us at <el-button tag="a" href="mailto:mail@avisfordele.dk" type="link" class="inline-flex">mail@avisfordele.dk</el-button> or contact your consultant</p>

      <ValidationObserver ref="loginForm">
        <form class="w-full" @submit.prevent="onSignIn">
          <div class="shadow-sm">
            <el-input
              v-model="email"
              placeholder="Email address"
              input-class="w-full rounded-t focus:z-10 relative"
              input-name="email"
              :rules="{ required: true, email: false }"
              :disabled="isLoading"
            />

            <el-input
              v-model="password"
              type="password"
              placeholder="Password"
              input-class="w-full rounded-b focus:z-10 relative"
              input-name="password"
              rules="required"
              :disabled="isLoading"
              class="-mt-1"
            />
          </div>

          <div class="flex justify-between items-center my-24">
            <el-checkbox v-model="rememberMe" :disabled="isLoading">Remember me</el-checkbox>
            <el-button tag="a" type="link" @click="$router.push({ name: 'ResetPassword' })">Forgot your password?</el-button>
          </div>

          <el-button
            tag="button"
            type="primary-dark"
            :loading="isLoading"
            class="w-full group"
          >
            <template #icon>
              <span class="icon-lock text-green text-2lg block group-hover:text-green-300" />
            </template>
            Sign in
          </el-button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

const ElButton = () => import('@/components/shared/ELButton')
const ElInput = () => import('@/components/shared/ELInput')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'LoginPage',

  components: { ElInput, ElCheckbox, ElButton },

  data () {
    return {
      email: '',
      password: '',
      rememberMe: false,
      isLoading: false
    }
  },

  computed: {
    logoImage () {
      return require('@/assets/images/paperboy-frontpage.png')
    }
  },

  created () {
    this.logout()
  },

  methods: {
    ...mapActions(['showNotification', 'login', 'logout']),

    ...mapMutations(['loading']),

    onSignIn () {
      this.$refs.loginForm.validate().then(isValid => {
        if (!isValid) {
          const errors = [...this.$refs.loginForm.errors.email, ...this.$refs.loginForm.errors.password]
          this.showNotification({ type: 'error', message: errors })
          return false
        } else {
          this.isLoading = true

          this.login({ email: this.email, password: this.password })
            .then(res => {
              this.isLoading = false
              const isRedirectedFrom = Object.prototype.hasOwnProperty.call(this.$route.query, 'redirectedFrom')
              let { redirectedFrom } = this.$route.query

              if (redirectedFrom) redirectedFrom = JSON.parse(redirectedFrom)

              if (isRedirectedFrom) {
                return this.$router.push({ name: redirectedFrom.routeName, params: redirectedFrom.params })
              }

              if (res.role === 'admin') {
                return this.$router.push({ name: 'Dashboard' })
              }
              if (res.role === 'advertiser') {
                return this.$router.push({ name: 'Orders' })
              }

              this.$router.push({ name: 'Orders' })
            })
            .catch(err => {
              if (err.status === 401) this.showNotification({ type: 'error', message: ['The provided credentials are not correct.', 'Please try again!'] })
              this.isLoading = false
            })
        }
      })
    }
  }
}
</script>
